import gsap from 'gsap';
import { _checkPlugin } from 'gsap/gsap-core';
import winterBg from '../assets/winter-bg.jpg';

export default class DressSanta {
    constructor() {
      this.dragging = false;
      this.svg = document.querySelector('svg'); console.log(this.svg)
      this.facialExpression = this.svg.getElementById('facial-expressions');
      this.addEvents();
      this.arrangeSVGs();
    }
  
    addEvents() {
      
      const categories = document.querySelectorAll('.show-category');
      categories.forEach( category => {
        category.addEventListener( 'click', e => this.showCategory(e) );
      });

      const sources = document.querySelectorAll("#elements svg");
      sources.forEach((source) => {
        if( !this.isTouchDevice() ){
          source.addEventListener("mousedown", e => this.dragStart(e));
        } else {
          source.addEventListener("touchstart", e => this.dragStart(e));
        }
      });
      if( !this.isTouchDevice() ){
        document.addEventListener("mousemove", e => this.drag(e));
        document.addEventListener("mouseup", e => this.drop(e) );
      } else {
        document.addEventListener("touchmove", e => this.drag(e));
        document.addEventListener("touchend", e => this.drop(e));
      }
      // Take photo
      document.getElementById('take-photo').addEventListener( 'click', (e) => this.takePhoto(e) );
    }
    
    arrangeSVGs(){
      const groups = document.querySelectorAll( '#elements g' );
      groups.forEach( group => {
        group.querySelectorAll( 'svg' ).forEach( ( svg, index ) => {
          svg.setAttribute( 'x', 120 + ( 22 * ( index % 3 ) ) );
          svg.setAttribute( 'y', 4 + ( 20 * Math.floor( index / 3 ) ) );
          svg.setAttribute( 'width', '15' );
          svg.setAttribute( 'height', '20' );
        })
      });
    }

    dragStart(e) {
      e.preventDefault();
      this.selected = e.currentTarget;
      this.dragging = !this.dragging;
      this.selected.classList.remove("resetting");
      this.selected.classList.add("dragging");

      // get offset for dragging SVG
      this.offset = this.getMousePosition(e);
      this.offset.x -= parseFloat( this.selected.getAttributeNS(null, "x") );
      this.offset.y -= parseFloat( this.selected.getAttributeNS(null, "y") );

      // set width to match viewbox
      const viewBox = this.selected.getAttribute( 'viewBox' ).split(' ');
      const width = viewBox[2];
      const height = viewBox[3];
      gsap.to(this.selected, { transformOrigin: "50% 50%", attr: { width: width, height: height} } );
      if (this.selected.getAttribute("data-snapped") !== "true") {
        this.selected.setAttribute("original-location", this.selected.parentNode.id );
        this.selected.setAttribute("originalX", this.selected.getAttribute('x') );
        this.selected.setAttribute("originalY", this.selected.getAttribute('y') );
      }
    }
  
    drag(e) {
      if (!this.dragging || !this.selected) return;
      e.preventDefault();
      const coord = this.getMousePosition(e);
      this.selected.setAttribute( 'x', coord.x - this.offset.x ); 
      this.selected.setAttribute( 'y', coord.y - this.offset.y ); 
    }
    
    drop(e) {
      if (!this.dragging || !this.selected) return;
  
      e.preventDefault();
      this.selected.classList.remove("dragging");

      if( this.selected.getAttribute('x') > 90 ){
        this.playWhooshSound();
        gsap.to(this.selected, { 
          transformOrigin: "50% 50%", 
          attr: { width: 15, height: 20, x: this.selected.getAttribute('originalX'), y: this.selected.getAttribute('originalY') } 
        } );

        this.selected.classList.remove('dropped');
        this.selected.setAttribute( 'data-snapped', 'false');
        this.svg.querySelector( '#' + this.selected.getAttribute('original-location') ).appendChild( this.selected, this.svg.querySelectorAll( '#' + this.selected.getAttribute('original-location') + ' svg' )[0] );
      } else {
        this.playClickSound();
        this.selected.classList.add("dropped");
        this.selected.setAttribute( 'data-snapped', 'true');
        this.svg.querySelector('#inserted-items').appendChild( this.selected, this.svg.querySelector('#inserted-items-pointer') );
      }

      this.updateFacialExpression();
      this.dragging = false;
      this.selected = false;
      this.target = false;
    }
   
    showCategory(e){ console.log('show category')
      const category = e.target.getAttribute( 'data-category' );
      const categories = document.querySelectorAll( '#elements > g');
      categories.forEach( category => category.classList.remove( 'open') );
      document.getElementById( category ).classList.add( 'open' );
    }

    updateFacialExpression(){
      const options = [
        'normal', 'angry', 'happy', 'sad', 'shocked', 'surprised', 'winking'
      ];

      const randomInt = Math.floor(Math.random() * options.length );

      options.forEach( (option, index) => {
        const expression = this.facialExpression.querySelector( '#facial-expression_' + option);
        expression.style.display = (index === randomInt) ? 'block' : 'none';
      })
    }

    takePhoto(e){
      // clone the SVG
      // const svgElement = this.svg.querySelector('#santa-base');
      // const clonedSvgElement = svgElement.cloneNode(true);
      // const {width, height} = svgElement.getBBox(); 

      // // create canvas
      // const canvas = document.createElement('canvas');
      // canvas.width = width*4;
      // canvas.height = height*4;
      // const context = canvas.getContext('2d');

      // // draw background image
      // // const bgImage = new Image();
      // // bgImage.onload = () => {
      // //   context.drawImage(bgImage, 0, 0, 600, 600); 
      // // }
      // // bgImage.src = 'winter-bg.6c51f127.jpg';

      // // draw santa image
      // const santaImage = new Image();
      // context.drawImage(santaImage, 0, 0, width*4, height*4 );
      // santaImage.onload = () => {
      //   const download = function(href, name){
      //     const link = document.createElement('a');
      //     link.download = name;
      //     link.style.opacity = "0";
      //     document.body.append(link);
      //     link.href = href;
      //     link.click();
      //     link.remove();
      //   }
      //   const png = canvas.toDataURL();
      //   download(png, "santa.png");
      // };

      // const outerHTML = clonedSvgElement.outerHTML;
      // const blob = new Blob([outerHTML],{type:'image/svg+xml;charset=utf-8'});
      // const URL = window.URL || window.webkitURL || window;
      // const blobURL = URL.createObjectURL( toDataURL());
      // santaImage.src = blobURL;

      // to upload?
      this.downloadSVGAsPNG();
      // play sound
      this.playShutterSound();
    }

    downloadSVGAsPNG(e){
      const canvas = document.createElement("canvas");
      const svg = document.querySelector('#santa-base');
      const base64doc = btoa(unescape(encodeURIComponent(svg.outerHTML)));
      const w = (82.4 * 4); //82.4 152.3
      const h = (152.3 * 4);
      const context = canvas.getContext("2d");
      canvas.setAttribute('width', w);
      canvas.setAttribute('height', h);

      // draw background image
      const bgImage = document.createElement('img');
      bgImage.src = winterBg;
      bgImage.onload = () => {
        console.log(w, h, 'loaded bg');
        context.drawImage(bgImage, 0, -1468 + h, 2726,  1468 ); 
        const photo = document.createElement('img');
        photo.src = 'data:image/svg+xml;base64,' + base64doc;
        console.log(w, h);
        photo.onload = () => {
          console.log('img loaded');
          //context.clearRect(0, 0, w, h);
          context.drawImage(photo, 0, 0, w, h);
          const dataURL = canvas.toDataURL('image/png');
          if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(canvas.msToBlob(), "download.png");
            e.preventDefault();
          } else {
            const a = document.createElement('a');
            const clickIt = new MouseEvent('click');
            a.download = 'download.png';
            a.href = dataURL;
            a.dispatchEvent(clickIt);
          }
      }
        // canvas.parentNode.removeChild(canvas);
      }  
    }
    
    playClickSound(){
      const click = new Audio( 'https://christmas2022.newenglish.co.uk/click.mp3' );
      click.play();
    }

    playWhooshSound(){
      const whoosh = new Audio( 'https://christmas2022.newenglish.co.uk/whoosh.mp3' );
      whoosh.play();
    }

    playShutterSound(){
      const shutter = new Audio( 'https://christmas2022.newenglish.co.uk/shutter.mp3' );
      shutter.play();
    }

    getDistance(pointA, pointB) {
      let y = pointB.x - pointA.x;
      let x = pointB.y - pointA.y;
      return Math.sqrt(x * x + y * y);
    }

    getMousePosition(e) {
        var CTM = this.svg.getScreenCTM();

        let clientX, clientY = null;
        if (e.clientX) {
            clientX = e.clientX;
            clientY = e.clientY;
        } else {
            clientX = e.changedTouches[0].clientX;
            clientY = e.changedTouches[0].clientY;
        }
        return {
          x: (clientX - CTM.e) / CTM.a,
          y: (clientY - CTM.f) / CTM.d
        };
    }

    isTouchDevice() {
      return (('ontouchstart' in window) ||
         (navigator.maxTouchPoints > 0) ||
         (navigator.msMaxTouchPoints > 0));
    }

  }